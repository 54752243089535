import axios from "axios";
import React, { useCallback, useState } from "react";
import {
  email,
  ImageField,
  ImageInput,
  required,
  SimpleForm,
  useNotify,
} from "react-admin";
import toast, { Toaster } from "react-hot-toast";
import { NODE_API } from "../../config";
import { history } from "../../helpers/history";
import { COUNTRY } from "../../constants/countries";
import { OTPEncryption, OTPGenerator } from "../../utils/encrypt";
import { Link } from "react-router-dom";

var CryptoJS = require("crypto-js");

const OrganizationForm = () => {
  const [activeTab, setActiveTab] = useState(0);
  const notify = useNotify();

  const [form1Data, setForm1Data] = useState({
    organizationName: "",
    domain: "",
    website: "",
    myFile: "",
  });
  const [form3Data, setForm3Data] = useState({
    address: "",
    city: "",
    locationName: "",
    country: "",
  });
  const [form2Data, setForm2Data] = useState({
    userName: "",
    number: "",
    email: "",
    designation: "",
    password: "",
    confirmPassword: "",
    otp: "",
  });
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [mailVerifyBtnDisabled, setMailVerifyBtnDisabled] = useState(false);
  const [generatedOTP, setGeneratedOtp] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [formErrors, setFormErrors] = useState({
    passwordMatch: false,
  });

  const isForm1Valid = Object.values(form1Data).every((value) => value !== "");
  const isForm3Valid = Object.values(form3Data).every((value) => value !== "");

  const isForm2Valid =
    Object.values(form2Data).every((value) => value !== "") &&
    form2Data.password === form2Data.confirmPassword;
  const isFormValid = isForm1Valid && isForm2Valid && isForm3Valid;

  const handleNext = () => {
    setActiveTab(1);
  };

  const handleNext2 = () => {
    setActiveTab(2);
  };

  const handleSave = async () => {
    if (isFormValid) {
      try {
        const response = await createOrgApiCall(
          form1Data,
          form2Data,
          form3Data
        );
        if (response.status === "error" && response.errorField === "email") {
          setEmailError(response.message);
        } else if (response.status == "success") {
          history.push("/login");
        }
      } catch (error) {
        console.error("API call failed", error);
      }
    }
  };

  const handleChangeForm1 = async (e) => {
    const files = e;
    if (!files) {
      toast.error("Image size should not be greater than 300kb");
    } else if (files.hasOwnProperty("path")) {
      const file = files;
      if (file.size > 300000) {
        setFormErrors((prevState) => ({ ...prevState, fileSize: true }));
        toast.error("Image size should not be greater than 300kb");
      } else if (!["image/png", "image/jpeg"].includes(file.type)) {
        setFormErrors((prevState) => ({ ...prevState, fileType: true }));
      } else {
        setFormErrors((prevState) => ({
          ...prevState,
          fileSize: false,
          fileType: false,
        }));
        const reader = new FileReader();
        reader.onloadend = () => {
          setForm1Data({
            ...form1Data,
            ["myFile"]: reader.result,
          });
        };
        reader.readAsDataURL(files);
      }
    } else {
      const { name, value, files } = e.target;
      setForm1Data({
        ...form1Data,
        [name]: value,
      });
    }
  };

  const handleChangeForm2 = (e) => {
    setForm2Data({
      ...form2Data,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "password" || e.target.name === "confirmPassword") {
      setFormErrors({
        ...formErrors,
        passwordMatch: form2Data.password !== form2Data.confirmPassword,
      });
    }
    if (e.target.name === "email") {
      setEmailError("");
    }
  };

  const handleChangeForm3 = (e) => {
    setForm3Data({
      ...form3Data,
      [e.target.name]: e.target.value,
    });
  };

  const handleVerifyEmail = async () => {
    // Call API to send OTP to the email
    const generatedOTPLocal = await OTPGenerator();
    setGeneratedOtp(generatedOTPLocal);
    var encryptedKey = OTPEncryption(generatedOTPLocal).toString();
    setMailVerifyBtnDisabled(true);
    const response = await sendMailOTP(form2Data.email, encryptedKey);
    if (response.status == "success") {
      setShowOtpInput(true);
    } else {
      setMailVerifyBtnDisabled(false);
    }
  };

  const handleVerifyOtp = () => {
    // Call API to verify OTP
    if (form2Data.otp == generatedOTP) {
      setIsEmailVerified(true);
      setShowOtpInput(false);
    } else {
      toast.error(`Enter valid OTP`);
    }
  };

  const sendMailOTP = (email, otp) => {
    return new Promise(async (resolve) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          email: email,
          key: otp,
        }),
      };

      // requestOptions
      try {
        const res = await axios(
          `${NODE_API}/email/verification`,
          requestOptions
        );
        resolve({ status: "success" });
        return res;
      } catch (e) {
        console.log("resssss error::", e);
        toast.error(`Something went wrong try again later`);
      }
    });
  };

  return (
    <>
      <div
        style={{ backgroundColor: "#f8fafb", padding: "1%", marginTop: "1%" }}
      >
        <div className="content mt-2">
          <div className="row">
            <Toaster
              position="top-center"
              reverseOrder={false}
              containerStyle={{ padding: "2%" }}
              toastOptions={{
                // Define default options
                className: "",
                duration: 5000,
                style: {
                  background: "#363636",
                  color: "#fff",
                },

                // Default options for specific types
                success: {
                  duration: 3000,
                  theme: {
                    primary: "black",
                    secondary: "green",
                  },
                },
                error: {
                  duration: 3000,
                  theme: {
                    primary: "red",
                    secondary: "black",
                  },
                },
              }}
            />

            <div className="col-md-5 order-md-2 mt-4 justify-content-center align-content-center">
              <img
                src="/images/undraw_file_sync_ot38.png"
                alt="Image"
                className="img-fluid mt-4"
              />
            </div>

            <div className="col-md-6 contents ">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div>
                    <h3>
                      <strong>Create Organization</strong>
                    </h3>
                  </div>
                  <div className="d-flex">
                    <div className="w-100" style={{ maxWidth: "600px" }}>
                      <div className="card-header">
                        <ul className="nav nav-tabs card-header-tabs">
                          <li className="nav-item">
                            <button
                              className={`nav-link text-sm ${
                                activeTab === 0 ? "active" : ""
                              }`}
                              onClick={() => setActiveTab(0)}
                            >
                              Organization Details
                            </button>
                          </li>
                          <li className="nav-item">
                            <button
                              className={`nav-link text-sm ${
                                activeTab === 1 ? "active" : ""
                              }`}
                              onClick={() => setActiveTab(1)}
                              disabled={!isForm1Valid}
                            >
                              Location Details
                            </button>
                          </li>
                          <li className="nav-item">
                            <button
                              className={`nav-link  text-sm ${
                                activeTab === 2 ? "active" : ""
                              }`}
                              onClick={() => setActiveTab(2)}
                              disabled={!isForm2Valid}
                            >
                              Personal Details
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="card-body">
                        {activeTab === 0 && (
                          <div>
                            <div className="form-group">
                              <span className="label text-sm">
                                Enter Organization Name*
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                name="organizationName"
                                placeholder="Organization Name"
                                value={form1Data.organizationName}
                                onChange={handleChangeForm1}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <span className="label text-sm">
                                Enter Domain*
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                name="domain"
                                placeholder="Domain"
                                value={form1Data.domain}
                                onChange={handleChangeForm1}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <span className="label text-sm">
                                Enter Website*
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                name="website"
                                placeholder="Website"
                                value={form1Data.website}
                                onChange={handleChangeForm1}
                                required
                              />
                            </div>

                            <div className="form-group">
                              {/* <span className="label text-sm">
                                Upload Organization Logo*
                              </span> */}

                              <SimpleForm toolbar={false}>
                                <ImageInput
                                  source="myFile"
                                  label="Organization Logo*"
                                  accept="image/png, image/jpeg"
                                  maxSize={300000}
                                  validate={[required]}
                                  placeholder="Drop a logo to upload, or click to select it"
                                  // onChange={handleChangeForm1}
                                  onChange={handleChangeForm1}
                                >
                                  <ImageField source="src" title="title" />
                                </ImageInput>
                              </SimpleForm>

                              <div className="text-danger text-sm">
                                File size should not exceed 300kb
                              </div>
                              {formErrors.fileType && (
                                <div className="text-danger">
                                  File type should be PNG or JPG
                                </div>
                              )}
                            </div>
                            <div className="d-flex justify-content-center">
                              <button
                                className="btn btn-primary btn-lg btn-block btn-sm"
                                onClick={handleNext}
                                disabled={!isForm1Valid}
                              >
                                Next
                              </button>
                            </div>
                            <span className="d-block text-left my-2 mb-0 text-muted">
                              Already have an account?
                              <Link
                                to="/login"
                                style={{
                                  color: "Highlight",
                                  cursor: "pointer",
                                }}
                              >
                                Login{" "}
                              </Link>
                              here
                            </span>
                          </div>
                        )}
                        {activeTab === 1 && (
                          <div>
                            <div className="form-group">
                              <span className="label text-sm">
                                Enter Location Name*
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                name="locationName"
                                placeholder="Location Name"
                                value={form3Data.locationName}
                                onChange={handleChangeForm3}
                                required
                              />
                            </div>

                            <div className="form-group">
                              <span className="label text-sm">
                                Enter Address*
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                name="address"
                                placeholder="Address"
                                value={form3Data.address}
                                onChange={handleChangeForm3}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <span className="label text-sm">Enter City*</span>
                              <input
                                type="text"
                                className="form-control"
                                name="city"
                                placeholder="City"
                                value={form3Data.city}
                                onChange={handleChangeForm3}
                                required
                              />
                            </div>

                            <div className="form-group">
                              <span className="label text-sm">
                                Select Country*
                              </span>
                              <select
                                className="form-control"
                                name="country"
                                value={form3Data.country}
                                onChange={handleChangeForm3}
                                required
                              >
                                <option value="">Select Country</option>
                                {COUNTRY.map((country) => (
                                  <option key={country.id} value={country.id}>
                                    {country.name}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="d-flex justify-content-center">
                              <button
                                className="btn btn-primary btn-lg btn-block btn-sm"
                                onClick={handleNext2}
                                disabled={!isForm1Valid}
                              >
                                Next
                              </button>
                            </div>
                            <span className="d-block text-left my-2 mb-0 text-muted">
                              Already have an account?
                              <Link
                                to="/login"
                                style={{
                                  color: "Highlight",
                                  cursor: "pointer",
                                }}
                              >
                                Login{" "}
                              </Link>
                              here
                            </span>
                          </div>
                        )}
                        {activeTab === 2 && (
                          <div>
                            <span className="label text-sm">Enter Email*</span>
                            <div className="form-group d-flex align-items-center">
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                placeholder="Email"
                                value={form2Data.email}
                                onChange={handleChangeForm2}
                                disabled={isEmailVerified}
                                required
                              />
                              {!isEmailVerified && (
                                <button
                                  className="btn btn-secondary ml-2 btn-sm"
                                  onClick={handleVerifyEmail}
                                  disabled={mailVerifyBtnDisabled}
                                >
                                  Verify
                                </button>
                              )}
                            </div>
                            {showOtpInput && (
                              <>
                                <span className="label text-sm">
                                  Enter Otp*
                                </span>
                                <div className="form-group d-flex align-items-center">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="otp"
                                    placeholder="Enter OTP"
                                    value={form2Data.otp}
                                    onChange={handleChangeForm2}
                                    required
                                  />
                                  <button
                                    className="btn btn-secondary btn-sm ml-2"
                                    onClick={handleVerifyOtp}
                                  >
                                    Verify
                                  </button>
                                </div>
                              </>
                            )}
                            {isEmailVerified && (
                              <>
                                <div className="form-group">
                                  <span className="label text-sm">
                                    Enter User Name*
                                  </span>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="userName"
                                    placeholder="User Name"
                                    value={form2Data.userName}
                                    onChange={handleChangeForm2}
                                    required
                                  />
                                </div>
                                <div className="form-group">
                                  <span className="label text-sm">
                                    Enter Contact Number*
                                  </span>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="number"
                                    placeholder="Number"
                                    value={form2Data.number}
                                    onChange={handleChangeForm2}
                                    required
                                  />
                                </div>
                                <div className="form-group">
                                  <span className="label text-sm">
                                    Enter Designation*
                                  </span>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="designation"
                                    placeholder="Designation"
                                    value={form2Data.designation}
                                    onChange={handleChangeForm2}
                                    required
                                  />
                                </div>
                                <div className="form-group">
                                  <span className="label text-sm">
                                    Enter Password*
                                  </span>
                                  <input
                                    type="password"
                                    className="form-control"
                                    name="password"
                                    placeholder="Password"
                                    value={form2Data.password}
                                    onChange={handleChangeForm2}
                                    required
                                  />
                                </div>
                                <div className="form-group">
                                  <span className="label text-sm">
                                    Enter Confirm Password*
                                  </span>
                                  <input
                                    type="password"
                                    className="form-control"
                                    name="confirmPassword"
                                    placeholder="Confirm Password"
                                    value={form2Data.confirmPassword}
                                    onChange={handleChangeForm2}
                                    required
                                  />
                                </div>
                              </>
                            )}
                            <div className="d-flex justify-content-center">
                              <button
                                className="btn btn-primary btn-lg btn-block btn-sm"
                                onClick={handleSave}
                                disabled={!isForm2Valid}
                              >
                                Save
                              </button>
                            </div>
                            <span className="d-block text-left my-2 mb-0 text-muted">
                              Already have an account?
                              <Link
                                to="/login"
                                style={{
                                  color: "Highlight",
                                  cursor: "pointer",
                                }}
                              >
                                Login{" "}
                              </Link>
                              here
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const createOrgApiCall = async (form1Data, form2Data, form3Data) => {
  return new Promise((resolve) => {
    var passwordEncryption = CryptoJS.SHA3(form2Data.password);

    var encryptedLoginPassword = passwordEncryption.toString(
      CryptoJS.enc.Base64
    );

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        name: form1Data.organizationName,
        domain: form1Data.domain,
        website: form1Data.website,
        address: form3Data.address,
        city: form3Data.city,
        locationName: form3Data.locationName,
        country: form3Data.country,
        designation: form2Data.designation,
        myFile: form1Data.myFile,
        email: form2Data.email,
        contactnumber: form2Data.number,
        username: form2Data.userName,
        password: encryptedLoginPassword,
      }),
    };

    // requestOptions
    return axios(`${NODE_API}/organization/create-organization`, requestOptions)
      .then(async (res) => {
        console.log("resssss::", res);
        if (res.data.status && res.data.status === "Duplicate entry") {
          toast.error(res.data.message);
          resolve({
            status: "error",
            errorField: "Error",
            message: "Something went wrong",
          });
        } else if (res.status === 200) {
          toast.success("Organization successfully registered");

          resolve({ status: "success", res });
        } else {
          toast.error(`Something went wrong try again later`);
          resolve({
            status: "error",
            errorField: "Error",
            message: "Something went wrong",
          });
        }

        return res;
      })
      .catch((e) => {
        console.log("resssss error::", e);
        toast.error(`Something went wrong try again later`);
      });
  });
};
export default OrganizationForm;
